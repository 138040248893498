import React from 'react'
import { v4 } from 'uuid'
import { ItemProd } from '../components/itemprod'

const futures = [
  {
    id: v4(),
    title: 'Фискальный накопитель на 15 месяцев',
    desc: '14900,00',
  },
  {
    id: v4(),
    title: 'Фискальный накопитель на 36 месяцев',
    desc: '21300,00',
  },
]

const data = {
  img: './images/fn.webp',
  title: 'Фискальный накопитель',
  desc: 'Сертифицированный в соответствии с 54-ФЗ фискальный накопитель. Программно-аппаратное шифровальное (криптографическое) средство защиты фискальных данных для вашей кассы.',
}

export const Fn = () => {
  return (
    <>
      <ItemProd data={data} futures={futures} />
    </>
  )
}
